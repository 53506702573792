<template lang="pug">
v-row.px-2.ma-0
  //GRID SENTIMIENTO
  <InfoCompetence :data="competenceWithChildren"></InfoCompetence>
  //GRID COMPETENCIA LAST YEAR
  div.margin-bottom-0.col-12(v-if="hasCompetence && (typeNode === 'brand' || typeNode === 'center')")
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0(v-if="!isLoading && data.length")
      .d-flex.headTitleGrid
        .block-title.titleGrid
          translate.mb-0.title-card(v-if="isGenericEntity") Competence. Evolution of the number of opinions per business
          translate.mb-0.title-card(v-else) Competence. Evolution of the number of opinions per restaurant
        .block-help.ml-auto
          v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-top="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            div
              p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución de los datos de la marca frente a sus competidores:  Media de las notas de todos los restaurantes /nº de restaurantes.

      box-competence-last-year(:chartData="dataCompetenceLastYearFilteredByBrand")

  //GRID VALORACIÓN MEDIA
  div.margin-bottom-0.col-12
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0(v-if="!isLoading")
      .d-flex.headTitleGrid
        .block-title.titleGrid
          translate.mb-0.title-card Average by Valuation
        .block-help.ml-auto
          v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="10" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            span {{helpAverageGroupByValoration}}
      .card-content
        .row.bodyIndicators.text-left
          #tableValoracionMedia.tableGlobal
            v-row.no-gutters.headerTableRanking.row.ma-0
              v-col.col-2.headerItem.col-name.textBlackGroup
                p.mb-0 #[translate Brand]
              v-col.col-3.headerItem.col-note.pl-8
                p.mb-0 #[translate Note]
              v-col.headerItem.col-opinions
                p.mb-0 #[translate N. opinions]
              v-col.col-2.headerItem.col-rating
                img(src="@/assets/img/aggregators/Just Eat.png" width="22")
              v-col.col-2.headerItem.col-rating
                img(src="@/assets/img/aggregators/glovo.svg" width="15")
              v-col.col-2.headerItem.col-rating
                img(src="@/assets/img/aggregators/Uber Eats.png" width="22")

            v-row.no-gutters.contentTableRanking.ma-0
              p.ml-3(v-if="!data") #[translate No data]
              // MAIN SECTOR
              v-row.no-gutters.rowMainSector.col-12.itemContent(v-if="data && data.length && data[0].sector")
                v-col.col-2.item.col-name
                  strong #[translate Average competence]
                v-col.col-3.item.col-note
                  .progressbar
                    span.meter.meter-long
                      span(v-if="data[0].sector.review_rating_global" :style="progressbarWidth(data[0].sector.review_rating_global)" :class="getProgressBarClass(data[0].sector.review_rating_global)")
                    span.ml-8.points.font-weight-med(v-if="data[0].sector.review_rating_global" :class="getProgressBarClass(data[0].sector.review_rating_global)") {{ data[0].sector.review_rating_global | formatNumber(1,1) }}

                v-col.item.col-opinions.text-right
                  p.mb-0
                    strong {{ data[0].sector.review_count | formatNumber }}

                v-col.col-2.col-rating
                  div.d-flex.justify-center.items-center(v-if="formatNumber(data[0]['sector']['delivery_ratings_dict']['JUS']['site_ratingv']) === '-'")
                    span -
                  div.d-flex.justify-center.items-center(v-else)
                    just-eat-rating
                    span(style="width:50px; text-align: right") {{ data[0]['sector']['delivery_ratings_dict']['JUS']['site_rating'] | formatNumber }}%

                v-col.col-2.col-rating
                  div.d-flex.justify-center.items-center(v-if="formatNumber(data[0]['sector']['delivery_ratings_dict']['GLV']['site_rating']) === '-'")
                    span -
                  div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                    GlovoRating(:percent="data[0]['sector']['delivery_ratings_dict']['GLV']['site_rating'] || 0 ")
                    span(style="width:50px; text-align: right") {{ data[0]['sector']['delivery_ratings_dict']['GLV']['site_rating'] | formatNumber }}%


                v-col.col-2.col-rating
                  div.d-flex.justify-center.items-center(v-if="formatNumber(data[0]['sector']['delivery_ratings_dict']['UBE']['site_rating']) === '-'")
                    span -
                  div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                    img(:src="img.imgStar")
                    span(style="width:50px; text-align: right") {{ data[0]['sector']['delivery_ratings_dict']['UBE']['site_rating'] | formatNumber }}


              v-row.no-gutters.itemContent.pa-0.col-12(v-for="(item, index) in dataFilteredByBrand" :key="index")
                v-col.col-2.item.col-name
                  span {{item.name}}
                v-col.col-3.item.col-note
                  .progressbar
                    span.meter.meter-long
                      span(v-if="item.review_rating_global" :style="progressbarWidth(item.review_rating_global)" :class="getProgressBarClass(item.review_rating_global)")
                    span.ml-8.points.font-weight-med(v-if="item.review_rating_global" :class="getProgressBarClass(item.review_rating_global)") {{ item.review_rating_global | formatNumber(1,1) }}

                v-col.item.col-opinions.text-right
                  p.mb-0 {{ item.review_count | formatNumber }}
                v-col.col-2.col-rating
                  div.d-flex.justify-center.items-center(v-if="formatNumber(item['delivery_ratings_dict']['JUS']['site_rating']) === '-'")
                    span(style="width: 96px") -
                  div.d-flex.justify-center.items-center(v-else)
                    just-eat-rating
                    span(style="width:50px; text-align: right") {{ item['delivery_ratings_dict']['JUS']['site_rating'] | formatNumber }}%


                v-col.col-2.col-rating
                  div.d-flex.justify-center.items-center(v-if="formatNumber(item['delivery_ratings_dict']['GLV']['site_rating']) === '-'")
                    span -
                  div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                    GlovoRating(:percent="item['delivery_ratings_dict']['GLV']['site_rating'] || 0 ")
                    span(style="width:50px; text-align: right") {{ item['delivery_ratings_dict']['GLV']['site_rating'] | formatNumber }}%

                v-col.col-2.col-rating
                  div.d-flex.justify-center.items-center(v-if="formatNumber(item['delivery_ratings_dict']['UBE']['site_rating']) === '-'")
                    span -
                  div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                    img(:src="img.imgStar")
                    span(style="width:50px; text-align: right") {{ item['delivery_ratings_dict']['UBE']['site_rating'] | formatNumber }}


  //GRID SENTIMIENTO POR CATEGORIAS
  box-feeling-category(v-if="$config.featureConfig.feelingCategory" :data="dataFilteredByBrand")

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import SrvNetwork from '@/services/delivery-network.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import BoxCompetenceLastYear from '@/components/network/CompetenceLastYear.vue'
import BoxFeelingCategory from '@/components/global/FeelingCategory.vue'
import StarRating from '@/components/common/starRating.vue'
import GlovoRating from '@/components/common/GlovoRating.vue'
import InfoCompetence from "@/components/competence/InfoCompetence.vue"
import brandMixin from '@/mixins/brand'
import justEatRating from '@/components/common/justEatRating.vue'

export default {
  mixins: [commonMixins, brandMixin],
  components: { StarRating, GlovoRating, BoxFeelingCategory, SkeletonCard, BoxCompetenceLastYear, InfoCompetence, justEatRating },
  data() {
    return {
      data: [],
      dataCompetenceLastYear: [],
      competenceWithChildren: [],
      helpAverageNOpinions: this.$gettext('Data of the brand compared to its competitors: total opinions of the brand / number of restaurants'),
      helpAverageGroupByValoration: this.$gettext('Data of the brand compared to its competitors: Average of the marks of all restaurants / number of restaurants'),
      competenceBrands: [],
      competenceBrandsSelected: [],
      img: {
        imgStar: require('@/assets/img/star.svg'),
      }
    }
  },
  created() {
    this.$store.commit('LOADING', true)

    if (['group'].includes(this.typeNode)) {
      this.$nextTick(() => {
        this.$router.push({ name: 'GlobalSummary', params: { paramCOD: this.$route.params.paramCOD }, query: this.$route.query })
      })
    } else {
      if (this.$store.getters.getNode > 0) {
        this.getCompetence()
        this.getCompetenceWithChildrens()
        if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
          this.getCompetenceLastYear()
        }
      }
    }
  },
  methods: {
    formatNumber(val, min = 0, max = 1) {
      if (typeof (val) !== 'string') {
        if (val === null) {
          return '-'
        }
        return Number(val).toLocaleString('de-De', { minimumFractionDigits: min, maximumFractionDigits: max })
      }
      return val
    },
    onCheckPaintCompetence(v) {
      this.competenceBrandsSelected = v
    },
    getCompetenceLastYear() {
      SrvNetwork.getCompetenceLastYear()
        .then(res => {
          if (res.status === 200) {
            res.data.forEach((e) => e.show = true)
            this.dataCompetenceLastYear = res.data
          }
        }, () => {
        })
    },
    progressbarWidth: function (points) {
      return 'width:' + (points * 10) + '%';
    },
    getProgressBarClass: function (points) {
      if (points < 5) {
        return 'minimun';
      } else if (points <= 7.5) {
        return 'medium';
      } else {
        return 'large';
      }
    },
    // METODO QUE EMITE EL COMPONENTE MULTISELECT
    onCheckSurveyTypes(val) {
      this.surveyTypesSelected = val
      this.$store.commit(types.SATISFACTION_FILTER_SURVEY_TYPES_SELECTED, val)
      this.getCompetence()
      this.getCompetenceWithChildrens()
      if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
        this.getCompetenceLastYear()
      }
    },
    // OBTENEMOS EL RANKING
    getCompetence: function () {
      this.$store.commit('LOADING', true)
      SrvNetwork.getCompetenceGlobal()
        .then(res => {
          if (res.status === 200) {
            this.data = res.data
            this.competenceBrands = []
            this.competenceBrandsSelected = []
            this.data.forEach((b, index) => {
              if (index > 0) {
                this.competenceBrands.push({ value: b.name, name: b.name })
              }
            })
            this.$store.commit('LOADING', false)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getCompetenceWithChildrens: async function () {
      this.$store.commit('LOADING', true)
      const res = await SrvNetwork.getCompetenceGlobalWithChildren()
      if (res.status === 200) {
        //brand_information
        if (res.data.brand_information && res.data.brand_information.length > 0) {
          this.competenceWithChildren.push({ showCompetence: false, items: res.data.brand_information })
        }

        //current_node
        if (res.data.current_node && res.data.current_node.length > 0) {
          this.competenceWithChildren.push({ showCompetence: false, items: res.data.current_node })
        }
        //children_data
        if (res.data.children_data && res.data.children_data.length > 0) {
          res.data.children_data.forEach((e) => {
            this.competenceWithChildren.push({ showCompetence: false, items: e })
          })

        }
      }
      this.$store.commit('LOADING', false)
    },
  },
  watch: {
    filterApply: function () {
      this.getCompetence();
      this.getCompetenceWithChildrens()
      if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
        this.getCompetenceLastYear()
      }
    },
    distanceMap(newVal) {
      if (newVal) {
        this.getCompetence();
        this.getCompetenceWithChildrens()
        if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
          this.getCompetenceLastYear()
        }
      }
    },
    getDistanceActive() {
      this.getCompetence();
      this.getCompetenceWithChildrens()
      if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
        this.getCompetenceLastYear()
      }
    }
  },
  computed: {
    distanceMap() {
      return this.$store.getters.getDistanceMap
    },
    getDistanceActive() {
      return this.$store.getters.getDistanceMapActive
    },
    brandsCompetenceActive() {
      return this.competenceBrandsSelected.map(c => c.value || c.code)
    },
    dataCompetenceLastYearFilteredByBrand() {
      let brands = this.dataCompetenceLastYear
      if (this.competenceBrandsSelected.length > 0) {
        brands = this.dataCompetenceLastYear.filter((b) => {
          if (this.brandsCompetenceActive.includes(b.name)) {
            return b
          }
        })
        brands = [this.dataCompetenceLastYear[0], this.dataCompetenceLastYear[1], ...brands]
      }
      return brands
    },
    dataFilteredByBrand() {
      let brands = this.data
      if (this.competenceBrandsSelected.length > 0) {
        brands = this.data.filter((b) => {
          if (this.brandsCompetenceActive.includes(b.name)) {
            return b
          }
        })
        brands = [this.data[0], ...brands]
      }
      return brands
    },
    dataFilteredByBrandForChart() {
      let brands = this.data
      if (this.competenceBrandsSelected.length > 0) {
        brands = this.data.filter((b) => {
          if (this.brandsCompetenceActive.includes(b.name)) {
            return b
          }
        })
      }
      if (this.typeNode !== 'group') {
        brands[0].competence = [brands[0].sector, ...brands.slice(1, brands.length)]
      } else {
        brands = [this.data[0], ...brands]
      }
      return brands
    },
    getDataSetOpinions() {
      let labels = []
      let datasets = [{ label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }]
      let bg = []
      let dataS = []
      // PRIMER HIJO DE DATA TIENE CAMPO COMPETENCE
      if (this.data[0] && this.data[0].sector) {
        for (let item in this.data) {
          //PRIMER ELEMENTO ES EL SECTOR PARA USAR LA MEDIA
          labels.push(this.data[item].name)
          if (item == 0) {
            bg.push('#F93549')
          } else {
            bg.push(this.colours[parseInt(item) + 1])
          }
          dataS.push(Math.round(this.data[item].avg_review_count))
        }
      }

      datasets[0].backgroundColor = bg
      datasets[0].data = dataS
      return { labels, datasets }
    },
    getDataSetSentiments() {
      let labels = []
      let datasets = [{ label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }, { label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }, { label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }]
      let bgPositive = '#89C445'
      let bgNegative = '#F36627'
      let bgNeutral = '#FCAE19'
      let dataPositive = []
      let dataNegative = []
      let dataNeutral = []
      if (this.data[0]) {
        labels.push(this.$gettext('Average competence'))
        dataPositive.push(this.$options.filters.formatNumber(this.data[0].sector.review_percent_positive, 1, 1).replace(',', '.'))
        dataNegative.push(this.$options.filters.formatNumber(this.data[0].sector.review_percent_negative, 1, 1).replace(',', '.'))
        dataNeutral.push(this.$options.filters.formatNumber(this.data[0].sector.review_percent_neutral, 1, 1).replace(',', '.'))
        for (let item in this.dataFilteredByBrand) {
          labels.push(this.dataFilteredByBrand[item].name)
          dataPositive.push(this.$options.filters.formatNumber(this.dataFilteredByBrand[item].review_percent_positive, 1, 1).replace(',', '.'))
          dataNegative.push(this.$options.filters.formatNumber(this.dataFilteredByBrand[item].review_percent_negative, 1, 1).replace(',', '.'))
          dataNeutral.push(this.$options.filters.formatNumber(this.dataFilteredByBrand[item].review_percent_neutral, 1, 1).replace(',', '.'))
        }
      }

      datasets[0].backgroundColor = bgPositive
      datasets[0].data = dataPositive
      datasets[0].label = this.$gettext('Positives')
      datasets[1].backgroundColor = bgNeutral
      datasets[1].data = dataNeutral
      datasets[1].label = this.$gettext('Neutral')
      datasets[2].backgroundColor = bgNegative
      datasets[2].data = dataNegative
      datasets[2].label = this.$gettext('Negatives')
      return { labels, datasets }
    },
    getAvgSector() {
      if (this.data[0] && this.data[0].sector) {
        return { 'sector_avg_review_count': this.data[0].sector.avg_review_count, 'sector_avg_rating_global': this.data[0].sector.review_rating_global }
      } else {
        return { 'sector_avg_review_count': 0, 'sector_avg_rating_global': 0 }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.chip {
  width: 20px;
  height: 8px;
  border-radius: 15px;

  &.chip-blue {
    background-color: #00A6D9;
  }

  &.chip-black {
    background-color: #222226;
  }

  &.chip-red {
    background-color: #F93549;
  }
}

.color-blue {
  font-size: 0.75rem;
  color: #00A6D9;
}

.color-black {
  font-size: 0.75rem;
  color: rgba(22, 22, 22, 0.8);
}

.color-red {
  font-size: 0.75rem;
  color: #F93549;
}

.tableGlobal {

  .headerTableRanking {
    .headerItem {
      text-align: right;
      font-size: 0.75rem;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: right;
      color: rgba(22, 22, 22, 0.8);

      img {
        cursor: pointer;
      }
    }
  }

  .contentTableRanking {
    .itemContent {
      text-align: center;
      font-size: 0.875rem;
      border-top: 1px solid #9797973d;
      height: 41px;
      display: flex;
      align-items: center;
    }
  }
}

.v-application #tableRanking .elevation-1 {
  box-shadow: none !important;
}

#tableValoracionMedia {
  .col-name {
    max-width: 13.33%;
    flex: 0 0 13.33%;
    margin-right: 2.5%;
    padding-left: 10px;
    text-align: left;
    justify-content: initial;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-note {
    max-width: 22.47%;
    flex: 0 0 22.47%;
    margin-right: 2.5%;
    text-align: left;
    justify-content: initial;
  }

  .col-opinions {
    margin-right: 2.5%;
  }

  .col-rating {
    flex: auto;
    justify-content: center;
    align-items: center;
    // max-width: 16%;
  }

}

.gridFull {
  .bodyIndicators {
    .progressbar {
      margin-bottom: 0;
    }
  }
}

.colBrand {
  width: 14%;
  flex: 14%;
  max-width: 14%;

  .colChartBrand {
    width: 10%;
    min-width: 90px;
    display: inline-block;
    margin-right: 1%;
  }
}

.rowMainSector {
  display: flex;
  width: 100%;
}

.rowFilterBrandCompetence {
  padding-left: 20px;

  .gr-filter-input-wrapper {
    margin-bottom: 0;
  }
}
</style>
